import {graphql} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React, {useMemo, useState} from "react";
import Search from "react-feather/dist/icons/search";

import Layout from "../components/Layout";
import StructuredData, {StructuredDataType} from "../components/StructuredData";
import SanityRichText from "../components/commons/SanityRichText";
import PartnerFooter from "../components/pages/partners/Footer";
import Partnerhero from "../components/pages/partners/Hero";
import MainBenefits from "../components/pages/partners/MainBenefits";
import PartnerReviews from "../components/pages/partners/Reviews";
import PartnerRoles from "../components/pages/partners/Roles";
import * as styles from "./partners/styles.module.scss";

const PartnersDirectory = ({partners = [], heading = "Partner Directory"}) => {
  const [sortBy, setSortBy] = React.useState("name");
  const [search, setSearch] = React.useState("");
  const [slice, setSlice] = useState(true);

  const partnersToRender = useMemo(() => {
    return partners
      .filter((p) => {
        return p.name.toLowerCase().includes(search.toLowerCase());
      })
      .sort((a, b) => {
        if (a[sortBy] < b[sortBy]) return -1;
        if (a[sortBy] > b[sortBy]) return 1;
        return 0;
      });
  }, [sortBy, search, partners]);

  return (
    <div className={styles.partnersContainer}>
      <div className={styles.header}>
        <h2 className={styles.heading}>{heading}</h2>
        <div className={styles.search}>
          <input
            className={styles.searchInput}
            placeholder='Search'
            type='text'
            onChange={(e) => {
              const value = e.target.value ?? "";
              setSlice(!value?.length);
              setSearch(value);
            }}
          />
          <Search className={styles.searchIcon} size={16} />
        </div>
        <div className={styles.sort}>
          <p className={styles.sortText}>Sort by:</p>
          <select
            className={styles.sortSelect}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option selected={sortBy === "name"} value='name'>
              Name
            </option>
            <option selected={sortBy === "type"} value='type'>
              Type
            </option>
          </select>
        </div>
      </div>
      <div className={styles.directory}>
        {partnersToRender.length ? (
          (slice ? partnersToRender.slice(0, 6) : partnersToRender).map(
            ({
              _rawDescription,
              logo: {
                asset: {gatsbyImageData},
              },
              alt,
              name,
              type,
              url,
            }) => {
              return (
                <OutboundLink
                  className={styles.partner}
                  key={name}
                  href={url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <div className={styles.top}>
                    <p className={styles.type}>{type}</p>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.partnerImage}>
                      <GatsbyImage
                        image={gatsbyImageData}
                        alt={alt || name}
                        imgClassName={styles.image}
                      />
                    </div>
                    <div className={styles.partnerInfo}>
                      <h2 className={styles.partnerName}>{name}</h2>
                      <SanityRichText
                        className={styles.partnerDescription}
                        blocks={_rawDescription}
                        renderContainerOnSingleChild
                      />
                    </div>
                  </div>
                </OutboundLink>
              );
            }
          )
        ) : (
          <p className={styles.noResults}>No results found</p>
        )}
      </div>
      <p
        className={styles.loadMore}
        hidden={!slice || !partnersToRender.length}
        onClick={() => setSlice(false)}
      >
        Load More
      </p>
    </div>
  );
};

export default function Partners({data = {}}) {
  const seoData = data.allSanitySeoMeta.nodes[0];

  const {
    benefits: {heading: benefitsHeading, benefits = []} = {},
    partnersSection: {heading: partnersHeading, partners = []} = {},
    hero,
  } = data?.allSanityPartnerPage?.nodes[0] ?? {};

  return (
    <Layout seoData={seoData}>
      <StructuredData
        type={StructuredDataType.BREADCRUMB}
        data={{
          name: "Partners",
          items: [{name: "Partners", item: "https://getmason.io/partners"}],
        }}
      />
      <Partnerhero data={hero} />
      <PartnerReviews />
      <PartnerRoles />
      <MainBenefits heading={benefitsHeading} benefits={benefits} />
      <PartnersDirectory partners={partners} heading={partnersHeading} />
      <PartnerFooter />
    </Layout>
  );
}

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "partners"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityPartnerPage {
      nodes {
        benefits {
          benefits
          heading
        }
        partnersSection {
          heading
          partners {
            _rawDescription
            logo {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
            name
            type
            url
          }
        }
        hero {
          _rawDescription
          heading
          subHeading
          form {
            companyLabel
            companyPlaceholder
            ctaId
            ctaSuccessText
            ctaText
            emailLabel
            emailPlaceholder
            heading
            nameLabel
            namePlaceholder
            phoneLabel
            phonePlaceholder
            sizeLabel
            sizeOptions
            typeLabel
            typeOptions
            typePlaceholder
          }
        }
      }
    }
  }
`;
