import {StyleExtractor} from "@kubric/utils";
import React from "react";
import Loader from "react-feather/dist/icons/loader";

import {COUNTRY_CODE_LIST} from "../../../../lib/constants";
import {partnersFormSubmission} from "../../../../utils/partners";
import {Dropdown} from "../../../Dropdown";
import SanityRichText from "../../../commons/SanityRichText";
// Old Partners Typeform CTA {../Button} - onClick opens up typeform modal
// import {Button} from "../Button";
import {HeroBGSVG} from "./HeroBGSVG";
import {HeroMobileBGSVG} from "./HeroMobileBGSVG";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const FormSubmitStatus = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
};

const PartnerHeroForm = ({data}) => {
  const {
    companyLabel,
    companyPlaceholder,
    ctaId,
    ctaSuccessText,
    ctaText,
    emailLabel,
    emailPlaceholder,
    heading,
    nameLabel,
    namePlaceholder,
    phoneDropdownOptions = COUNTRY_CODE_LIST,
    phoneLabel,
    phonePlaceholder,
    sizeLabel,
    sizeOptions = [],
    typeLabel,
    typeOptions = [],
    typePlaceholder,
  } = data || {};
  const [formData, setFormData] = React.useState({
    name: "",
    company: "",
    email: "",
    phoneCountry: "",
    phone: "",
    size: "",
    type: "",
  });
  const {name, company, email, phone, size, type} = formData;
  const updateFormData = (key, value) => {
    setFormData({...formData, [key]: value});
  };
  const [submitStatus, setSubmitStatus] = React.useState(FormSubmitStatus.IDLE);

  return (
    <div className={styler.get("pHeroForm")}>
      <span className={styler.get("heading")}>{heading}</span>
      <div className={styler.get("formInput")}>
        <span className={styler.get("label")}>{nameLabel}</span>
        <input
          type='text'
          className={styler.get("input")}
          value={name}
          placeholder={namePlaceholder}
          onChange={(e) => updateFormData("name", e.target.value)}
        />
      </div>
      <div className={styler.get("formInput")}>
        <label className={styler.get("label")}>{companyLabel}</label>
        <input
          type='text'
          className={styler.get("input")}
          value={company}
          placeholder={companyPlaceholder}
          onChange={(e) => updateFormData("company", e.target.value)}
        />
      </div>
      <div className={styler.get("formGroup")}>
        <div className={styler.get("formInput")}>
          <label className={styler.get("label")}>{emailLabel}</label>
          <input
            type='text'
            className={styler.get("input")}
            value={email}
            placeholder={emailPlaceholder}
            onChange={(e) => updateFormData("email", e.target.value)}
          />
        </div>
        <div className={styler.get("formInput")}>
          <label className={styler.get("label")}>{phoneLabel}</label>
          <div className={styler.get("formDropdownInput")}>
            <Dropdown
              theme={{
                dropdownWrapper: styler.get("formDropdown"),
                dropdownPreview: styler.get("dropdownTrigger"),
                dropdownList: styler.get("dropdownList"),
              }}
              options={phoneDropdownOptions}
              onSelect={(option) => updateFormData("phoneCountry", option)}
              defaultValue='US'
            />
            <input
              type='text'
              className={styler.get("input")}
              value={phone}
              placeholder={phonePlaceholder}
              onChange={(e) => updateFormData("phone", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={styler.get("formSelect")}>
        <label className={styler.get("label")}>{sizeLabel}</label>
        <div className={styler.get("selectOptions")}>
          {sizeOptions.map((option) => (
            <span
              className={styler.get([
                "selectOption",
                option === size && "selected",
              ])}
              onClick={() => updateFormData("size", option)}
            >
              {option}
            </span>
          ))}
        </div>
      </div>
      <div className={styler.get("formSelect")}>
        <label className={styler.get("label")}>{typeLabel}</label>
        <Dropdown
          theme={{
            dropdownWrapper: styler.get("formDropdown"),
            dropdownPreview: styler.get(["dropdownTrigger", !type && "empty"]),
            dropdownList: styler.get("dropdownList"),
          }}
          options={typeOptions}
          placeholder={typePlaceholder}
          onSelect={(option) => updateFormData("type", option)}
          optionRenderer={(option) => {
            return (
              <span className={styler.get("dropdownOption")}>{option}</span>
            );
          }}
        />
      </div>
      <button
        type='button'
        className={styler.get(["cta", submitStatus])}
        id={ctaId}
        onClick={() => {
          setSubmitStatus(FormSubmitStatus.LOADING);
          try {
            partnersFormSubmission({...formData}).then(() =>
              setSubmitStatus(FormSubmitStatus.SUCCESS)
            );
          } catch (e) {
            setSubmitStatus(FormSubmitStatus.IDLE);
          }
        }}
      >
        {submitStatus === FormSubmitStatus.IDLE && ctaText}
        {submitStatus === FormSubmitStatus.LOADING && (
          <Loader width={24} className={styler.get("loader")} />
        )}
        {submitStatus === FormSubmitStatus.SUCCESS && ctaSuccessText}
      </button>
    </div>
  );
};

const PartnerHero = ({data}) => {
  const {form, heading, subHeading, _rawDescription} = data || {};
  return (
    <div className={styler.get("pHeroWrap", "full-bleed relative")}>
      <HeroBGSVG className={styler.get(["bgSvg", "hideInMobile"])} />
      <HeroMobileBGSVG className={styler.get(["bgSvg", "hideInDesktop"])} />
      <div className={styler.get("pHeroContent")}>
        <h1 className={styler.get("heading")}>{heading}</h1>
        <h3 style={{color: "#DB6B5C"}} className={styler.get("subHeading")}>
          {subHeading}
        </h3>
        <SanityRichText
          renderContainerOnSingleChild
          className={styler.get("description")}
          blocks={_rawDescription}
        />
        {/* Old Partners Typeform CTA {../Button} - onClick opens up typeform modal */}
        {/* <Button /> */}
      </div>
      <PartnerHeroForm data={form} />
    </div>
  );
};

export default PartnerHero;
