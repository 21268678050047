import "regenerator-runtime/runtime";

const axios = require("axios").default;

// to send partners form data to slack workflow (#partners-leads)
export const partnersFormSubmission = async (data) => {
  try {
    await axios
      .put("/api/partnersForm", {
        ...data,
        env: process.env.GATSBY_ENV,
      })
      .catch((e) => console.debug(e));
  } catch (e) {
    console.debug(e);
  }
};
