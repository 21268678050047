import {StyleExtractor} from "@kubric/utils";
import React, {useState} from "react";
import ArrowRightCircle from "react-feather/dist/icons/arrow-right-circle";
import Slider from "react-slick";

import Arrow from "../../../../assets/Arrow.svg";
import {useMediaQuery} from "../../../../hooks/useMediaQuery";
import EmailCTA from "../../../commons/EmailCTA";
import {Button} from "../Button";
import "../Rolestabs/overrides.scss";
import * as styles from "../Rolestabs/styles.module.scss";

const MutatedArrow = ({
  color = "black",
  className = "",
  onClick,
  prev = false,
}) => {
  return (
    <Arrow
      style={{"--color": color}}
      className={`${prev ? styles.prevArrow : styles.nextArrow} ${className}`}
      onClick={onClick}
    />
  );
};

const CTA = ({url, text, tags}) =>
  url === null ? (
    <Button className='primary-cta' cta={text || "Become a Partner"} />
  ) : (
    <EmailCTA
      data={{
        action: "mmGetStartedWithASRedirect",
        cta: text,
        bgColor: "var(--almost_black)",
        textColor: "white",
      }}
      tags={tags}
      theme={{container: styles.cta}}
    />
  );

const RolesTabs = ({
  data,
  type = "A",
  cta_url: ctaUrl = null,
  initialTab = 0,
  location = {},
}) => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const styler = new StyleExtractor(styles);
  const params = new URLSearchParams(location.search);
  const utmSource = params.get("utm_source");
  const utmMedium = params.get("utm_medium");
  const [activeTab, setActiveTab] = useState(initialTab);
  const [activeItem, setActiveItem] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    touchMove: true,
    arrows: true,
    autoplay: false,
    nextArrow: <MutatedArrow color={data[activeItem].color} />,
    prevArrow: <MutatedArrow prev color={data[activeItem].color} />,
    afterChange(index) {
      setActiveItem(index);
    },
  };
  return (
    <div className={styler.get("rolesTabsWrap")}>
      {isMobile ? (
        <>
          <Slider {...settings}>
            {data.map((tab) => {
              return (
                <>
                  <div className='flex flex-col gap-4'>
                    <h3
                      className={styles.coloredHeader}
                      style={{backgroundColor: tab.color}}
                    >
                      {tab.name}
                    </h3>
                    <div className='tc-display mx-auto w-6/12'>{tab.img}</div>
                  </div>
                </>
              );
            })}
          </Slider>
        </>
      ) : (
        <>
          <div className='tab-grp flex flex-col md:flex-row'>
            {data.map((tab, i) => (
              <div className='tab-wrap mx-auto md:mx-2 w-11/12 md:w-1/3'>
                <div
                  style={{
                    borderColor: tab.color,
                    color: activeTab === i ? "white" : tab.color,
                    backgroundColor:
                      activeTab === i ? tab.color : "transparent",
                  }}
                  className={styler.get(
                    "roleTab",
                    "flex items-center justify-between md:justify-center px-2 md:px-0 py-2 m-2"
                  )}
                  onClick={() => {
                    activeTab === i ? setActiveTab(null) : setActiveTab(i);
                  }}
                >
                  {tab.name}
                  <div
                    className={styler.get([
                      "mobile",
                      "arrRight",
                      activeTab === i && "open",
                    ])}
                  >
                    <ArrowRightCircle />
                  </div>
                </div>
                {activeTab === i ? (
                  <div className={styler.get("mobile", "tab-content mb-4")}>
                    <div className='tc-display mx-auto w-6/12'>{tab.img}</div>
                    <div className='tc-content text-center w-full p-2 box-border'>
                      <p>{tab.desc}</p>
                      <br />
                      {tab.benifits && (
                        <div className='roles-tabs-benifits-wrap'>
                          <h3 className='font-normal opacity-80'>BENEFITS</h3>
                          <div className='all-benifits'>
                            {tab.benifits.map((b) => (
                              <div className='benifit my-4'>
                                <h4>{b.title}</h4>
                                <p>{b.desc}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      <br />
                      <CTA
                        text={tab.cta_text}
                        url={ctaUrl}
                        tags={[
                          ...(utmSource ? [utmSource] : []),
                          ...(utmMedium ? [utmMedium] : []),
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
          {data.map((tab, i) => (
            <div className={styler.get("desktopOnly")}>
              <div
                className={`tab-content my-12 flex ${
                  type === "A" ? "flex-row" : "flex-row-reverse"
                } ${activeTab === i ? "" : "hidden"}`}
              >
                <div className='tc-display w-2/4'>{tab.img}</div>
                <div className='tc-content w-2/4 pl-8 box-border'>
                  <h2
                    style={{
                      color: tab.color,
                      fontSize: "var(--BIG)",
                      letterSpacing: "-.1rem",
                      lineHeight: "1.2",
                      textAlign: "left",
                    }}
                    className='medium'
                  >
                    {tab.title}
                  </h2>
                  <br />
                  <p>{tab.desc}</p>
                  <br />
                  {tab.benifits && (
                    <div className='roles-tabs-benifits-wrap'>
                      <h3 className='font-normal opacity-80'>BENEFITS</h3>
                      <div className='all-benifits'>
                        {tab.benifits.map((b) => (
                          <div className='benifit my-4'>
                            <h4>{b.title}</h4>
                            <p>{b.desc}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <br />
                  <CTA text={tab.cta_text} url={ctaUrl} />
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default RolesTabs;
