import {graphql, useStaticQuery} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React, {useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import Arrow from "../../../../assets/Arrow.svg";
import HeadingRightElement from "../../../../assets/automation-heading-styler.svg";
import HeadingLeftElement from "../../../../assets/review-heading-styler.svg";
import {useMediaQuery} from "../../../../hooks/useMediaQuery";
import HeadTwo from "../../../HeadTwo/HeadTwo";
import {Button} from "../Button";
import "./styles.scss";

const MutatedArrow = ({className = "", onClick, prev = false}) => {
  return (
    <Arrow
      className={`arrow-navs ${prev ? "prev" : "next"} ${className}`}
      onClick={onClick}
    />
  );
};

const PartnerReviews = () => {
  const [selectedReviewIndex, setReviewIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width: 900px)");
  const data = useStaticQuery(graphql`
    {
      allSanityPartReview {
        nodes {
          img {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
          link
          name
          designation
          txt
          bg_color
        }
      }
    }
  `);

  const slideSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 5000,
    variableWidth: true,
    nextArrow: <MutatedArrow />,
    prevArrow: <MutatedArrow prev />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className='partner-review-wrap full-bleed layouted pt-20 pb-0 md:py-12'>
      <HeadTwo
        classes='main-heading'
        showOtherContent
        otherContent={
          <>
            <HeadingRightElement className='heading-right' />
            <HeadingLeftElement className='heading-left' />
          </>
        }
      >
        <span>Meet our</span> {isMobile && <br />}Top Partners
      </HeadTwo>
      <div className='partner-slide-wrap w-full md:w-3/4 md:pt-8 md:pb-12 pt-4 pb-0'>
        <Slider {...slideSettings}>
          {data.allSanityPartReview.nodes.map((c, i) => (
            <OutboundLink
              target='_blank'
              href={c.link}
              className={`revSlide block pb-4 ${
                selectedReviewIndex === i ? "selSlide" : ""
              }`}
              onClick={() => setReviewIndex(i)}
              rel='noopener noreferrer'
            >
              <div className='rev-img-wrap rounded-md'>
                <div
                  style={{
                    backgroundColor: c.bg_color !== null ? c.bg_color : "white",
                  }}
                  className='border-box rounded-full w-20 h-20 flex justify-center items-center shadow-md'
                >
                  <GatsbyImage
                    objectFit='contain'
                    className='h-full'
                    image={c.img.asset.gatsbyImageData}
                    alt={c.alt}
                  />
                </div>
              </div>
              <p className='revTxt text-center font-light px-2'>"{c.txt}"</p>
              <div className='mt-auto text-center'>
                <h3 className='text-lg'>{c.name}</h3>
                <p className='text-lg font-light'>{c.designation}</p>
              </div>
            </OutboundLink>
          ))}
        </Slider>
        <br />
        <br />
      </div>
      {isMobile && (
        <div className='flex flex-row justify-center'>
          <Button className='primary-cta inline-block text-center mb-20' />
        </div>
      )}
    </div>
  );
};

export default PartnerReviews;
