import React from "react";

export const ChevronFilled = ({height = 24, width = 24, className, color}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      fill='none'
      color={color}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M16.5 9.75L12 14.25L7.5 9.75' fill='#808693' />
      <path
        d='M16.5 9.75L12 14.25L7.5 9.75H16.5Z'
        stroke='#808693'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
