import {StyleExtractor, isFunction, isNull} from "@kubric/utils";
import React from "react";

import {ChevronFilled} from "../icons/ChevronFilled";
import * as styles from "./styles.module.scss";

export const Dropdown = ({
  options = [],
  onSelect,
  placeholder = null,
  optionRenderer,
  theme = {},
  defaultValue = null,
}) => {
  const styler = new StyleExtractor(styles, theme);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(null);

  // if no placeholder is provided, select the first option by default
  React.useEffect(() => {
    if (!isNull(defaultValue)) {
      setSelectedValue(defaultValue);
      onSelect(defaultValue);
    } else if (isNull(placeholder)) {
      setSelectedValue(options[0]);
      onSelect(options[0]);
    }
  }, [placeholder, defaultValue]);

  // if there's no selection, show placeholder value
  const triggerElementValue = isNull(selectedValue)
    ? placeholder
    : selectedValue;

  return (
    <div className={styler.get("dropdownWrapper")}>
      <div
        className={styler.get([
          "dropdownPreview",
          isNull(selectedValue) && "empty",
        ])}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <span>
          {isFunction(optionRenderer)
            ? optionRenderer(triggerElementValue)
            : triggerElementValue}
        </span>
        <ChevronFilled
          height={30}
          width={30}
          className={styler.get(["chevron", dropdownOpen && "openChevron"])}
        />
      </div>
      {dropdownOpen && (
        <div className={styler.get("dropdownList")}>
          {options.map((option) => {
            return (
              <span
                className={styler.get("dropdownOption")}
                onClick={() => {
                  setSelectedValue(option);
                  setDropdownOpen(false);
                  onSelect(option);
                }}
              >
                <div className={styler.get("radio")}>
                  <input
                    type='radio'
                    name='traffic'
                    checked={option === selectedValue}
                    value={option}
                  />
                </div>
                {isFunction(optionRenderer) ? optionRenderer(option) : option}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};
