// extracted by mini-css-extract-plugin
export var arrRight = "styles-module--arrRight--2xu6q";
export var benefits = "styles-module--benefits--3gF8S";
export var coloredDesc = "styles-module--coloredDesc--3cGQI";
export var coloredHeader = "styles-module--coloredHeader--21uAg";
export var desktopOnly = "styles-module--desktopOnly--2n0jO";
export var mobile = "styles-module--mobile--sUDI7";
export var mobileCta = "styles-module--mobileCta--2a6rW";
export var nextArrow = "styles-module--nextArrow--3drhi";
export var open = "styles-module--open--Oo1TH";
export var prevArrow = "styles-module--prevArrow--GSu4a";
export var roleTab = "styles-module--roleTab--1pBtn";
export var rolesTabsWrap = "styles-module--rolesTabsWrap--1YP1Z";