import {StaticImage} from "gatsby-plugin-image";
import React from "react";

import HeadingStyleElement from "../../../../assets/review-heading-styler.svg";
import {useMediaQuery} from "../../../../hooks/useMediaQuery";
import HeadTwo from "../../../HeadTwo/HeadTwo";
import RolesTabs from "../OlderRolesTab";
import "./styles.scss";

const feats = [
  {
    name: "Influencer",
    title: "Influencer",
    link: "/partners/affiliate",
    color: "#ED9D00",
    desc: "Your followers love your content for the eCommerce insights, product recommendations, and great benefits. And we love that! Because at Mason, we're all about helping sellers connect their brand story to customer journeys through no-code dynamic content across online stores.",
    img: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/7396c4cb-9492-4878-a127-a7b6a027f24e.png'
        alt='placeholder'
        placeholder='blurred'
      />
    ),
    benifits: [
      {
        title: "Revenue Sharing",
        desc: "20% of the subscription value for every new user referred.",
      },
      {
        title: "Priority Support",
        desc: "Dedicated Account Manager to support you and your clients.",
      },
      {
        title: "Transparent Tracking",
        desc: "Unique codes to manage all of your referrals and revenue.",
      },
    ],
  },
  {
    name: "Agency or Freelancer",
    title: "Agency/Freelancer Partner",
    link: "/partners/agency",
    color: "#FE6960",
    desc: "Help your clients nail CRO with no-code, v to create the best shopping experiences",
    img: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/16daf981-1f7c-4795-8dbc-9d05f34f146f.png'
        alt='placeholder'
        placeholder='blurred'
      />
    ),
    benifits: [
      {
        title: "Best-in-class Commission",
        desc: "Get 20% revenue month on month for every user.",
      },
      {
        title: "Dedicated Account Manager",
        desc: "Maximize your earnings through priority support.",
      },
      {
        title: "Co-marketing",
        desc: "Work with us on blogs, events and webinars to increase your brand awareness.",
      },
    ],
  },
  {
    name: "Shopify Apps",
    title: "Shopify App Developers",
    link: "/partners/shopifyapp",
    color: "#943462",
    desc: "Collaboration can transform good SaaS solutions into great ones! We're always on the lookout for technology partners who are as determined to enhance the eCommerce ecosystem as we are. ",
    img: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/52771845-686c-404c-8ab6-7dfa840faa8e.png'
        alt='placeholder'
        placeholder='blurred'
      />
    ),
    benifits: [
      {
        title: "Easy Integration",
        desc: "Customized integration options with our low-code plug & play platform.",
      },
      {
        title: "Developer Hub",
        desc: "Dedicated developer partner to help with any integration issues.",
      },
      {
        title: "Co-marketing",
        desc: "Events, content, and more collaborative initiatives to build brand presence",
      },
    ],
  },
];

const PartnerRoles = () => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  return (
    <div className='partner-roles-wrap'>
      <HeadTwo
        classes='mb-20'
        showOtherContent
        otherContent={<HeadingStyleElement className='heading-right' />}
      >
        Why become a {isMobile && <br />} Partner?
      </HeadTwo>
      {/* this is older one as MM design was updated */}
      <RolesTabs data={feats} initialTab={1} />
    </div>
  );
};

export default PartnerRoles;
