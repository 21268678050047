import * as React from "react";

export const HeroBGSVG = ({className, onClick}) => (
  <svg
    className={className}
    onClick={onClick}
    width='1440'
    height='526'
    viewBox='0 0 1440 526'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M0 110.697L1440 0V413.148L0 526V110.697Z' fill='#FFF2F2' />
  </svg>
);
