import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import Check from "react-feather/dist/icons/check";

import DesignElement from "../../../../assets/HomeDesignElement.svg";
import {useMediaQuery} from "../../../../hooks/useMediaQuery";
import {Button} from "../Button";
import "./styles.scss";

const actions = [
  {
    img: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/4cad15ee-b97b-4f98-ade3-41f7e3ebbf2d.png'
        alt=''
      />
    ),
    txt: (
      <span>
        Fill the partner application form{" "}
        <Button cta='here' className='underline text-blue-600' />
      </span>
    ),
  },
  {
    img: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/0f7cf546-736a-4dfc-90b7-7b0286a39d28.png'
        alt=''
      />
    ),
    txt: "Get connected to a partnership manager within 24 hours",
  },
  {
    img: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/5ab96773-4a9c-434e-857e-258e90ee26e6.png'
        alt=''
      />
    ),
    txt: "#SpreadTheMagic & grow together",
  },
];

const MainBenefits = ({heading, benefits = []}) => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  return (
    <div className='full-bleed main-container relative flex flex-col'>
      {!isMobile && (
        <>
          <DesignElement className='upper-design-element' />
          <div className='p-main-b-wrap my-12 px-4 md:px-0'>
            <h2
              className='text-center medium text-5xl'
              style={{letterSpacing: "-0.1rem"}}
            >
              Ready to Become a Mason Partner?
            </h2>
            <br />
            <h2
              className='text-center medium secondary-heading mt-12'
              style={{letterSpacing: "-0.1rem", lineHeight: "1.2"}}
            >
              HOW IT WORKS
            </h2>
            <div className='benifits-grid flex flex-col md:flex-row my-8 items-start'>
              {actions.map((card) => (
                <div className='benifit-card my-4 md:my-0 flex flex-col justify-center items-center w-full md:w-1/3'>
                  <div className='h-18 w-18 flex items-center justify-center mb-4'>
                    {card.img}
                  </div>
                  <h4 className='text-center w-1/2 medium text-lg'>
                    {card.txt}
                  </h4>
                </div>
              ))}
            </div>
          </div>
          <DesignElement className='lower-design-element' />
        </>
      )}
      <div className='p-b2-wrap my-12'>
        {!isMobile && (
          <h2
            className='text-center medium secondary-heading'
            style={{letterSpacing: "-0.1rem", lineHeight: "1.2"}}
          >
            {heading}
          </h2>
        )}
        <div className='benifitsGrid my-8'>
          {benefits.map((benefit) => (
            <div className='benefit mx-auto my-4' key={benefit}>
              <Check size={24} className='benefitCheck' />
              <p className='benefitTxt'>{benefit}</p>
            </div>
          ))}
        </div>
      </div>
      {isMobile && <Button className='primary-cta inline-block my-2 mx-auto' />}
    </div>
  );
};

export default MainBenefits;
