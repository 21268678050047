import * as React from "react";

export const HeroMobileBGSVG = ({className, onClick}) => (
  <svg
    className={className}
    onClick={onClick}
    width='360'
    height='666'
    viewBox='0 0 360 666'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M0 50.3198L360 0V614L0 666V50.3198Z' fill='#FFF2F2' />
  </svg>
);
